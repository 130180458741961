import _noop from 'lodash/noop'
import { createContext, type ReactElement, useContext, useEffect, useState } from 'react'

import { axiosInstance, interceptResponse } from '@shared/helpers/axios'
import {
    accessCookieName,
    expirationDateCookieName,
    getCookie,
    refreshCookieName,
    removeCookie,
    setCookie,
} from '@shared/helpers/cookies'
import { useQueryClient } from '@tanstack/react-query'

interface AuthContextProps {
    login: (token: string, refreshTokenExpireAt: number, refreshToken: string) => void
    logout: () => void
    isAuthenticated: boolean
}

/* Context */
const AuthContext = createContext<AuthContextProps>({
    login: _noop,
    logout: _noop,
    isAuthenticated: false,
})
AuthContext.displayName = 'AuthContext'

const portalChannel = new BroadcastChannel('connexions-portal-auth-channel')

/* Provider */
function AuthProvider(props: { children: ReactElement }): ReactElement {
    const { children } = props
    const queryClient = useQueryClient()

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => !!getCookie(accessCookieName))

    const setAuthCookies = (token: string, refreshToken: string, refreshTokenExpireAt: number) => {
        const expirationDateString = refreshTokenExpireAt.toString()
        setCookie(accessCookieName, token, refreshTokenExpireAt)
        setCookie(refreshCookieName, refreshToken, refreshTokenExpireAt)
        setCookie(expirationDateCookieName, expirationDateString, refreshTokenExpireAt)
        setIsAuthenticated(true)
    }

    const login = (token: string, refreshTokenExpireAt: number, refreshToken: string): void => {
        portalChannel.postMessage({ type: 'login', payload: { token, refreshToken, refreshTokenExpireAt } })
        setAuthCookies(token, refreshToken, refreshTokenExpireAt)
    }

    const removeAuthCookies = () => {
        removeCookie(accessCookieName)
        removeCookie(refreshCookieName)
        removeCookie(expirationDateCookieName)
        setIsAuthenticated(false)
    }

    const logout = (): void => {
        portalChannel.postMessage({ type: 'logout' })
        removeAuthCookies()
        queryClient.clear()
    }

    useEffect(() => {
        function handleMessages(event: MessageEvent<any>) {
            const data = event.data
            if (data.type === 'login') {
                const { token, refreshToken, refreshTokenExpireAt } = data.payload
                setAuthCookies(token, refreshToken, refreshTokenExpireAt)
            } else if (data.type === 'logout') {
                removeAuthCookies()
            }
        }

        portalChannel.addEventListener('message', handleMessages)
        return () => portalChannel.removeEventListener('message', handleMessages)
    }, [])

    useEffect(() => {
        const { eject } = interceptResponse(async (error) => {
            const originalRequest = error.config
            if (!originalRequest) return Promise.reject(error)

            const status = error.response?.status || 0

            // @ts-expect-error retry doesn't exist
            if ([401, 403].includes(status) && !originalRequest._retry) {
                // @ts-expect-error retry doesn't exist
                originalRequest._retry = true // Mark the request as retried to avoid infinite loops.

                const refreshToken = getCookie(refreshCookieName)

                if (!refreshToken) {
                    logout()
                    return Promise.reject(error)
                }

                try {
                    const refreshRequest = (await axiosInstance.post(
                        '/token-refresh',
                        { refreshToken },
                        { headers: { skipAuth: true } }
                    )) as { data: { token: string } }

                    const token = refreshRequest.data.token
                    const expirationDateValue = getCookie(expirationDateCookieName)
                        ? Number.parseInt(getCookie(expirationDateCookieName))
                        : undefined
                    setCookie(accessCookieName, token, expirationDateValue)

                    return axiosInstance(originalRequest)
                } catch (e) {
                    logout()
                }
            }

            return Promise.reject(error)
        })

        return (): void => {
            eject()
        }
    }, [isAuthenticated])

    return (
        <AuthContext.Provider
            value={{
                login,
                logout,
                isAuthenticated,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

/* useContext */
function useAuth(): AuthContextProps {
    const context = useContext(AuthContext)

    if (context === undefined) {
        throw new Error(`AuthContext must be used within a AuthProvider`)
    }

    return context
}

export { AuthProvider, useAuth }
