import type { SidebarItem } from './types'
import { CreditCardIcon, UserCircleIcon, UserGroupIcon } from '@heroicons/react/24/outline'

export const sidebarItems: SidebarItem[] = [
    {
        label: 'accessories',
        route: '/accessories',
        icon: <CreditCardIcon />,
    },
    {
        label: 'profiles',
        route: '/profiles',
        icon: <UserCircleIcon />,
    },
    {
        label: 'connections',
        route: '/connections',
        icon: <UserGroupIcon />,
    },
    // {
    //     label: 'dashboard',
    //     route: '/dashboard',
    //     icon: <RectangleGroupIcon />,
    // },
    //
    // {
    //     label: 'settings',
    //     route: '/settings',
    //     icon: <Cog6ToothIcon />,
    // },
]
