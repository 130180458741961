import { cn } from '@shared/helpers/utils'
import { sidebarItems } from '../constants'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

export function SidebarMobile({ isSidebarOpen, toggleSidebar }: { isSidebarOpen: boolean; toggleSidebar: () => void }) {
    const { t } = useTranslation()
    const location = useLocation()

    return (
        <div
            className="fixed inset-0 z-20 size-full bg-neutral-400 bg-opacity-50"
            style={{ marginTop: 'var(--app-bar-height)', display: isSidebarOpen ? 'block' : 'none' }}
            onClick={toggleSidebar}
        >
            <div
                className="flex h-screen flex-col items-center gap-md bg-white pt-md shadow-md"
                style={{ width: 'var(--side-bar-width-open)' }}
                onClick={(e) => e.stopPropagation()}
            >
                {sidebarItems.map((item) => {
                    const { label, route, icon } = item

                    return (
                        <Link
                            onClick={toggleSidebar}
                            to={route}
                            key={label}
                            className={cn(
                                'flex h-[48px] w-3/4 items-center gap-sm rounded-xl px-md capitalize text-black no-underline',
                                {
                                    'bg-primary text-white': location.pathname.startsWith(route),
                                },
                                {
                                    'hover:bg-secondary': !location.pathname.startsWith(route),
                                }
                            )}
                        >
                            <span className="size-6 cursor-pointer">{icon}</span>
                            <span>{t(label)}</span>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}
