import { useAuth } from './AuthContainer'
import { useLocation, Navigate } from 'react-router-dom'

interface Props {
    element: JSX.Element
}
/*
 * PrivateRoute is a route that only allows authenticated users to access it.
 */
export function PrivateRoute({ element }: Props): JSX.Element | null {
    const { pathname, search } = useLocation()
    const { isAuthenticated } = useAuth()
    // this will also make sure to preserve any other query params
    const redirectUrl = encodeURIComponent(`${pathname}${search}`)

    return isAuthenticated ? (
        element
    ) : (
        <Navigate
            to={{ pathname: '/login', search: pathname !== '/' || search ? `redirect=${redirectUrl}` : undefined }}
        />
    )
}
