import { axiosInstance } from '@shared/helpers/axios'
import { useQuery } from '@tanstack/react-query'
import { QueriesKeys } from '../../constants'
import type { User } from './types'

export function useProfileOwnerQuery() {
    return useQuery<User>({
        queryKey: [QueriesKeys.useProfileOwnerQuery],

        queryFn: () => axiosInstance.get('/users/me?module=digitalCard', {}).then((res) => res?.data),
    })
}
