import axios, { type AxiosError, type AxiosResponse } from 'axios'
import { accessCookieName, getCookie } from '@shared/helpers/cookies'
import _set from 'lodash/set'

export const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: Number(import.meta.env.VITE_API_TIMEOUT),
    headers: {
        'Content-Type': 'application/json',
    },
})

axiosInstance.interceptors.request.use((config) => {
    const token = getCookie(accessCookieName)
    if (!config.headers?.skipAuth && token) _set(config, 'headers.Authorization', `Bearer ${token}`)

    return config
})

export function interceptResponse(onError: (err: AxiosError) => Promise<AxiosError | AxiosResponse>) {
    const id = axiosInstance.interceptors.response.use((res) => res, onError)

    return {
        eject: () => axiosInstance.interceptors.response.eject(id),
    }
}
